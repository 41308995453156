import './components';

import { handlePopupClose } from '../../auto/utils/components/pop-up';
import { setDropdownInteration } from '../../auto/utils/components';
import equalHeights from '../../auto/utils/equalHeights';
import serviceConversionFactory from '../../auto/autoBoxOfficeServiceFactory';
import helpers from '../../auto/helpers';
import { createCookieUTM } from '../../auto/utils/utm';
import { initSectionWhenItIsVisible } from '../../auto/utils/initSectionWhenItIsVisible';
import hookConversion from '../../auto/utils/hookConversion';

createCookieUTM();

window.helpers = {
  ...helpers,
  initSectionWhenItIsVisible,
};
window.serviceConversion = serviceConversionFactory();
window.components = {
  setDropdownInteration,
};
window.matchHeight = {
  match: equalHeights,
};
window.hookConversion = hookConversion;

handlePopupClose();
