/* eslint-disable react/no-unused-prop-types */
// eslint-disable-next-line no-unused-vars
import { h, Component } from 'preact';
import PropTypes from 'prop-types';
import { getPayload } from '%/utils/forms/getPayload';
import validationFactory from '%/utils/validation';
import handleInputChange from '%/utils/handleInputChange';
import { mask } from '%/utils/formUtils/maks/mask';
import { setRecaptchaLoadOnFormFocus } from '%/utils/reCAPTCHA';
import { isSafari } from '%/utils/isSafari';
import FormWrapper from '../../FormComponents/FormWrapper';
import FormMessageOverlay from '../../../FormMessageOverlay';
import FormBody from '../../FormComponents/FormBody';
import FieldGroup from '../../FormComponents/FieldGroup';
import Input from '../../FormComponents/Fields/Input';
import SubmitButton from '../../FormComponents/SubmitButton';
import FormFooter from '../../FormComponents/FormFooter';
import Checkbox from '../../FormComponents/Fields/Checkbox';
import ContactFieldGroup from '../../FormComponents/Fields/ContactFieldGroup';
import Select from '../../FormComponents/Fields/Select';
import FormHeader from '../../FormComponents/FormHeader';

export default class PerformanceWhatsappForm extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      name: null,
      email: null,
      phone: null,
      cpf: '',
      unit: null,
      mailing: null,
      whatsapping: null,
      phoning: null,
      dataPermissions: false,
      formState: {
        isSubmitting: false,
        errorSending: false,
        showOverlay: false,
      },
    };

    this.state = this.initialState;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseMessageOverlay = this.handleCloseMessageOverlay.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.clearFields = this.clearFields.bind(this);
    this.openWhatsAppLink = this.openWhatsAppLink.bind(this);
  }

  componentDidMount() {
    if (this.phoneInput) {
      mask(this.phoneInput, { mask: 'phone' }, phoneMaskvalue =>
        this.setState({ phone: phoneMaskvalue }),
      );
    }

    if (this.cpfInput) {
      mask(this.cpfInput, { mask: 'cpf' }, cpfMaskValue =>
        this.setState({ cpf: cpfMaskValue }),
      );
    }

    this.validator = validationFactory(`#${this.props.formId}`);
    this.updateStateWithPropData();

    setRecaptchaLoadOnFormFocus();
  }

  handleSubmit(event) {
    event.preventDefault();

    const invalidInputs = this.validator.validateAll(this.form);
    const formIsValid = invalidInputs.length === 0;
    if (!formIsValid) return;

    const whatsappFormBaitId = '-6';

    const payload = {
      ...getPayload(this.state, this.props),
      bait: whatsappFormBaitId,
      bait_type: 'whatsapp',
      version: this.getActiveVersionSlug(),
    };

    this.setState({
      formState: { isSubmitting: true },
    });

    window.serviceConversion
      .convert(payload)
      .then(() => {
        this.setState({
          formState: {
            isSubmitting: false,
            errorSending: false,
          },
        });
        return {
          data: payload,
          status: 'success',
        };
      })
      .catch(error => {
        this.setState({
          formState: {
            isSubmitting: false,
            errorSending: true,
          },
        });
        console.error(error);
        return {
          data: error,
          status: 'error',
        };
      })
      .then(result => {
        document.dispatchEvent(
          new CustomEvent('new_conversion', {
            detail: {
              status: result.status,
              data: result.data,
            },
          }),
        );
        this.setState(oldState => ({
          formState: {
            ...oldState,
            showOverlay: true,
          },
        }));
        this.openWhatsAppLink();
      });
  }

  handleCloseMessageOverlay() {
    this.setState(oldState => ({
      formState: {
        ...oldState,
        showOverlay: false,
      },
    }));
  }

  getActiveVersionSlug() {
    if (!window.store || !window.store.vehicleVersionDetails) return null;

    const { hasModel } = this.props;

    if (!hasModel) return null;

    const activeVersion =
      window.store.vehicleVersionDetails.getState().activeVersion;

    return activeVersion.slug;
  }

  updateStateWithPropData() {
    const { unit } = this.props;

    this.setState({
      unit,
    });
  }

  openWhatsAppLink() {
    const { link } = this.props;

    if (link) {
      if (isSafari()) window.location.href = link;
      else window.open(link, '_blank');
    }
  }

  clearFields() {
    this.setState(() => this.initialState);
  }

  render() {
    const {
      formId,
      showCpf,
      showUnits,
      showContactOptions,
      showDataPermissions,
      dataPermissionsCustomText,
      linkPrivacyPolicy,
      showEmail,
    } = this.props;

    return (
      <div className="whatsapp-form">
        <FormWrapper
          customClasses="whatsapp-form__wrapper"
          formId={formId}
          handleSubmit={this.handleSubmit}
          setRef={form => {
            this.form = form;
          }}
        >
          <FormMessageOverlay
            handleClose={this.handleCloseMessageOverlay}
            isVisible={this.state.formState.showOverlay}
            type={this.state.formState.errorSending ? 'error' : 'success'}
          />
          <FormHeader
            customClasses="whatsapp-form__header"
            title="Fale pelo whatsapp"
          />
          <FormBody customClasses="whatsapp-form__body">
            <p className="whatsapp-form__subtitle">
              Preencha o formulário abaixo e converse agora com um consultor:
            </p>

            <FieldGroup>
              <Input
                title="Nome"
                name="name"
                value={this.state.name}
                type="text"
                placeholder="Nome"
                handleChange={this.handleInputChange}
                required
              />
              {showEmail && (
                <Input
                  title="E-mail"
                  name="email"
                  value={this.state.email}
                  type="email"
                  placeholder="E-mail"
                  handleChange={this.handleInputChange}
                  required
                />
              )}
              <Input
                title="Telefone"
                name="phone"
                value={this.state.phone}
                type="phone"
                autoComplete="off"
                placeholder="Telefone/Whatsapp"
                handleChange={this.handleInputChange}
                setRef={phoneInput => {
                  this.phoneInput = phoneInput;
                }}
                required
              />
              {showCpf && (
                <Input
                  title="CPF"
                  name="cpf"
                  value={this.state.cpf}
                  type="text"
                  placeholder="CPF"
                  handleChange={this.handleInputChange}
                  setRef={cpfInput => {
                    this.cpfInput = cpfInput;
                  }}
                  required
                  minLength={14}
                />
              )}
              {showUnits && (
                <Select
                  name="unit"
                  options={this.props.units}
                  value={this.state.unit}
                  label="Escolha a unidade:"
                  placeholder="Selecione uma unidade"
                  handleChange={this.handleInputChange}
                />
              )}
            </FieldGroup>
            {showContactOptions && (
              <ContactFieldGroup
                handleChange={this.handleInputChange}
                mailing={this.state.mailing}
                whatsapping={this.state.whatsapping}
                phoning={this.state.phoning}
              />
            )}
            {showDataPermissions && (
              <FieldGroup>
                <Checkbox
                  label={dataPermissionsCustomText}
                  name="dataPermissions"
                  value={this.state.dataPermissions}
                  isChecked={this.state.dataPermissions}
                  checked
                  customClasses="form__field-checkbox-data-permissions dark"
                  handleChange={this.handleInputChange}
                />
              </FieldGroup>
            )}
            <FormFooter
              customClasses="whatsapp-form__body-footer"
              linkPrivacyPolicy={linkPrivacyPolicy}
            />
          </FormBody>
          <div className="whatsapp-form__footer">
            <button
              className="whatsapp-form__clear-fields-button"
              type="button"
              onClick={this.clearFields}
            >
              Limpar campos
            </button>
            <SubmitButton
              label={this.props.buttonLabel}
              customClasses="whatsapp-form__submit-button button--whatsapp"
              isSubmitting={this.state.formState.isSubmitting}
            />
          </div>
        </FormWrapper>
      </div>
    );
  }
}

PerformanceWhatsappForm.defaultProps = {
  formId: null,
  buttonLabel: 'Converse agora!',
  dataPermissionsCustomText:
    'Autorizo o uso de minhas informações pessoais para campanhas de marketing.',
  link: null,
  showContactOptions: true,
  unit: '',
  hasModel: false,
  showEmail: true,
};

PerformanceWhatsappForm.propTypes = {
  formId: PropTypes.string,
  buttonLabel: PropTypes.string,
  product: PropTypes.string,
  channel: PropTypes.string,
  category: PropTypes.string,
  brand: PropTypes.string,
  showCpf: PropTypes.bool,
  showUnits: PropTypes.bool,
  showContactOptions: PropTypes.bool,
  showEmail: PropTypes.bool,
  linkPrivacyPolicy: PropTypes.string,
  showDataPermissions: PropTypes.bool,
  dataPermissionsCustomText: PropTypes.string,
  link: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  unit: PropTypes.string,
  hasModel: PropTypes.bool,
};
