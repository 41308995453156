import { conversionEventWithUserData } from './conversionEventWithUserData';
import { isDDMShowroom } from './isDDMShowroom';

/**
 * Essa função roda sempre que uma conversão for enviada com sucesso,
 *
 * @param {object} Dados da conversão enviados para a API
 */
export default function autoHookConversion(conversion) {
  // Envia um evento de conversão para o Google Analytics
  // eslint-disable-next-line no-undef
  ga('send', 'event', 'Conversões');
  const eventName = 'conversion';

  const gtmEvent = isDDMShowroom()
    ? conversionEventWithUserData(eventName, conversion)
    : { event: eventName };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(gtmEvent);

  if (isDDMShowroom() && window.dataOffer) {
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "BRL",
        value: window.dataOffer.price,
        items: [
          {
            item_id: window.dataOffer.id,
            item_name: window.dataOffer.name,
            affiliation: window.dataChannel.name,
            item_brand: window.dataOffer.brand,
            item_category: window.dataOffer.category,
            price: window.dataOffer.price
          }
        ]
      }
    });
  }
}
