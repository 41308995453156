// eslint-disable-next-line no-unused-vars
import { h } from 'preact';
import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Input extends Component {
  render() {
    const {
      customClasses,
      showLabels,
      title,
      name,
      value,
      type,
      min,
      max,
      autoComplete,
      minLength,
      maxLength,
      required,
      placeholder,
      handleChange,
      setRef,
    } = this.props;

    return (
      <div className={classNames('form__text-field', customClasses)}>
        {showLabels && (
          <label className="form__text-field-label" htmlFor={name}>
            {title}
          </label>
        )}
        <input
          className="form__text-field-input form__text-field-common"
          name={name}
          value={value}
          type={type}
          required={required}
          placeholder={placeholder}
          onChange={handleChange}
          autoComplete={autoComplete}
          ref={setRef}
          min={min}
          max={max}
          minLength={minLength}
          maxLength={maxLength}
          data-bouncer-target={`#invalid-${name}`}
        />
        <div id={`invalid-${name}`} className="invalid-feedback" />
      </div>
    );
  }
}

Input.defaultProps = {
  autoComplete: 'on',
};

Input.propTypes = {
  customClasses: PropTypes.string,
  showLabels: PropTypes.bool,
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type: PropTypes.string,
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  setRef: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
};
